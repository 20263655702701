<template>
    <div style="display: flex;align-items: center;justify-content: center">
        <div class="box_wrap" style="width: 80%;height: 80%">
            <iframe src="https://www.singlewindow.shaanxi.cn" frameborder="0" style="width: 100%;height: 100%"></iframe>
        </div>
    </div>
</template>

<script>
export default {
	name: "trademarksT"
}
</script>

<style scoped>

</style>
